import uuid from "react-uuid";

export const getMyID = () => {
    let id = localStorage.getItem('pi4ot-id');
    if (!id) {
        id = uuid();
        localStorage.setItem('pi4ot-id', id);
    }
    return id;
}
