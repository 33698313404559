import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from "react";
import {BsList} from "react-icons/bs";
import './infonav.component.css';
import Logo from "./logo.component";


export class InfonavComponent extends Component {

    render() {

        return (
            <div className="info-nav">
                <div className="info-nav-left">
                    <a href="/"><Logo/></a>
                </div>
                <div className="info-nav-right">
                    {this.props.showMenu && (
                    <button className="btn btn-link btn-sm btn-menu"
                            onClick={() => this.props.onMenuClicked()}>
                        <BsList/>
                    </button>
                    )}
                </div>
            </div>
        );
    }

}
