import {initializeApp} from "firebase/app";
import {child, get, getDatabase, onValue, ref, push, set, update} from "firebase/database";
import uuid from 'react-uuid';
import {firebaseConfig} from "../firebase_config";


const app = initializeApp(firebaseConfig);

export const newSession = (board, move, p1, p2, next) => {
    const db = getDatabase(app);
    const newSessionID = uuid();
    const sessionData = {
        started: new Date().toUTCString(),
        history: [],
        state: {
            board,
            move,
            p1,
            p2,
            next
        }
    }
    console.log(newSessionID, sessionData);
    return set(ref(db, 'session/' + newSessionID), sessionData).then(() => {
        console.log("fb: new session created");
        return newSessionID;
    });
}

export const getSessionData = (sessionID) => {
    const dbRef = ref(getDatabase(app));
    return get(child(dbRef, 'session/' + sessionID)).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val();
        } else {
            return null;
        }
    }).catch((error) => {
        console.error(error);
    });
}

export const registerPlayer2WithSession = (sessionID, myID) => {
    const db = getDatabase(app);
    return set(ref(db, 'session/' + sessionID + '/state/p2'), myID).then(() => {
        console.log("fb: registered with session");
    });
}

export const updateHistory = (sessionID, move) => {
    const db = getDatabase(app);
    const historyRef = ref(db, 'session/' + sessionID + '/history');
    const newPostRef = push(historyRef);
    return set(newPostRef, move);
}

export const updateMove = (sessionID, move, board, next) => {
    const db = getDatabase(app);
    const updates = {};
    updates['session/' + sessionID + '/state/move'] = move;
    updates['session/' + sessionID + '/state/board'] = board;
    updates['session/' + sessionID + '/state/next'] = next;
    return update(ref(db), updates);
}

export const resetSession = (sessionID, next) => {
    const db = getDatabase(app);
    const updates = {};
    updates['session/' + sessionID + '/state/move'] = "reset";
    updates['session/' + sessionID + '/state/next'] = next;
    return update(ref(db), updates);
}

export const listenToSessionChange = (sessionID, onSessionChange) => {
    const db = getDatabase(app);
    const sessionRef = ref(db, 'session/' + sessionID + '/state');
    return onValue(sessionRef, (snapshot) => {
        onSessionChange(snapshot.val());
    });
}

