import {
    BOARD_ACTIONS,
    BOARD_DO_NOTHING,
    BOARD_ROTATE_LEFT,
    BOARD_ROTATE_RIGHT,
    BOARD_UPSIDE_DOWN
} from "./actions";
import {
    STATE_WIN_DEUCE,
    STATE_WIN_PLAYER_ONE,
    STATE_WIN_PLAYER_TWO
} from "./states";
import {
    NO_PLAYER,
    PLAYER_ONE,
    PLAYER_TWO
} from "./constants";

export const createNewBoard = (dim) => {
    return [...Array(dim).keys()].map(() => { return [...Array(dim).keys()].map(() => NO_PLAYER)});
}

export const copyBoard = (board) => {
    return board.map(function (row) {
        return row.slice();
    });
}

export const canPlaceStoneInColumn = (board, column_idx) => {
    return board[0][column_idx] === 0;
}

export const placeStoneInColumn = (board, column_idx, player) => {
    let new_board = copyBoard(board);
    let stone_set = false;
    for (let row_idx = board.length - 1; row_idx >= 0; row_idx--) {
        if (board[row_idx][column_idx] === 0) {
            new_board[row_idx][column_idx] = player;
            stone_set = true;
            break;
        }
    }
    return new_board;
}

export const getClassnameForAction = (action) => {
    switch (action) {
        case BOARD_ROTATE_RIGHT: return 'rotate_right';
        case BOARD_ROTATE_LEFT: return 'rotate_left';
        case BOARD_UPSIDE_DOWN: return 'turn_upside_down';
        default: return 'no_rotation';
    }
}

export const rotateBoard = (board, action) => {
    if (! BOARD_ACTIONS.includes(action)) {
        throw Error("Unknown action: " + action);
    }
    const dim = board.length;
    let new_board = createNewBoard(dim);

    if (BOARD_DO_NOTHING === action) {
        new_board = copyBoard(board);
    }
    if (BOARD_UPSIDE_DOWN === action) {
        for (let c = 0; c < dim; c++) {
            let c_idx = dim - 1 - c
            let r_idx = dim - 1
            for (let r = 0; r < dim; r++) {
                if (board[r][c] !== NO_PLAYER) {
                    new_board[r_idx][c_idx] = board[r][c]
                    r_idx -= 1
                }
            }
        }
    }
    if (BOARD_ROTATE_LEFT === action) {
        for (let r = 0; r < dim; r++) {
            let c_idx = r
            let r_idx = dim - 1
            for (let c = 0; c < dim; c++) {
                if (board[r][c] !== NO_PLAYER) {
                    new_board[r_idx][c_idx] = board[r][c]
                    r_idx -= 1
                }
            }
        }
    }
    if (BOARD_ROTATE_RIGHT === action) {
        for (let r = 0; r < dim; r++) {
            let c_idx = dim - 1 - r
            let r_idx = dim - 1
            for (let c = dim - 1; c >= 0; c--) {
                if (board[r][c] !== NO_PLAYER) {
                    new_board[r_idx][c_idx] = board[r][c]
                    r_idx -= 1
                }
            }
        }
    }
    return new_board;
}

let getColumn = (board, col) => {
    let column = [];
    for(let i = 0; i < board.length; i++){
        column.push(board[i][col]);
    }
    return column;
}

export let getDiagonals = (board, min_length) => {
    const dim = board.length;
    const dim_stop = dim - min_length;
    let diagonals = [];
    for(let col = 0; col <= dim_stop; col++) {
        let diag_down_upper = [];
        for(let xi = col; xi < dim; xi++){
            diag_down_upper.push(board[xi-col][xi]);
        }
        diagonals.push(diag_down_upper);

        if (col !== 0) {
            let diag_down_lower = [];
            for(let xi = col; xi < dim; xi++){
                diag_down_lower.push(board[xi][xi-col]);
            }
            diagonals.push(diag_down_lower);
        }

        let diag_up_upper = [];
        for(let xi = dim - 1 - col; xi >= 0; xi--){
            diag_up_upper.push(board[xi][dim -1 - xi - col]);
        }
        diagonals.push(diag_up_upper);

        if (col !== 0) {
            let diag_up_lower = [];
            for(let yi = col; yi < dim; yi++){
                diag_up_lower.push(board[dim - 1 - yi + col][yi]);
            }
            diagonals.push(diag_up_lower);
        }
    }
    return diagonals;
}

/**
 *  Return board state as an integer.
 *
 *  Example:
 *  [1, 0]
 *  [1, 2]
 *
 *  1. flatten and flip: [2,1,0,1]
 *  2. encode ternary number (left to right): 2 * 3^0 + 1 * 3^1 + 0 * 3^2 + 1 * 3^3 = 32
 *
 * @param board the board array
 * @returns {number} encoded board state
 */
export let hash_code = (board) => {
    let res = 0;
    for (let i = 0; i < board.length; i++) {
        for (let j = 0; j < board.length; j++) {
            const x = board.length - 1 - i;
            const y = board.length - 1 - j;
            const fact = j + board.length * i;
            res += Math.pow(3, fact) * board[x][y];
        }
    }
    return res;
}

class WinCounter {
    constructor(seq_length) {
        if (seq_length < 2) {
            throw Error('Seq length to short.');
        }
        this.counts_win1 = 0;
        this.counts_win2 = 0;
        this.check_win1 = PLAYER_ONE.toString().repeat(seq_length);
        this.check_win2 = PLAYER_TWO.toString().repeat(seq_length);
    }

    evaluate(sequence) {
        let seq_str = sequence.join('');
        let win1 = seq_str.includes(this.check_win1) ? 1 : 0;
        this.counts_win1 += win1;
        let win2 = seq_str.includes(this.check_win2) ? 1 : 0;
        this.counts_win2 += win2;
        return [win1, win2];
    }

    evaluate_board(board) {
        const dim = board.length;

        let full_rows = 0;
        for (let i = 0; i < dim; i++) {
            if (board[i].indexOf(NO_PLAYER) === -1) {
                full_rows += 1;
            }
        }
        if (full_rows === dim) {
            this.counts_win1 +=1;
            this.counts_win2 +=1;
        }
    }

    getWinner() {
        if (this.counts_win1 === 0 && this.counts_win2 === 0) {
            return null;
        } else if (this.counts_win1 === this.counts_win2) {
            return STATE_WIN_DEUCE;
        } else if (this.counts_win1 > this.counts_win2) {
            return STATE_WIN_PLAYER_ONE;
        } else if (this.counts_win2 > this.counts_win1) {
            return STATE_WIN_PLAYER_TWO;
        } else {
            throw Error('Unknown game state.');
        }
    }
}

export const determineBoardWinner = (board, seq_length = 4) => {
    const dim = board.length;
    const counter = new WinCounter(seq_length);

    for (let i = 0; i < dim; i++) {
        // horizontal check
        counter.evaluate(board[i]);
        // vertical check
        counter.evaluate(getColumn(board, i));
    }
    // diagonal check
    const diagonals = getDiagonals(board, seq_length);
    diagonals.forEach(diag => {
        counter.evaluate(diag);
    })
    // evaluate board is full -> deuce
    counter.evaluate_board(board);
    return counter.getWinner();
}