import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from "react";
import Stone from "./components/stone.component";
import {animateElem} from "./utils/animationutils";
import {choice} from "./utils/bot";
import './Start.css'

export class Start extends Component {

    constructor(props) {
        super(props);
        this.logoRefs = {
            "p": React.createRef(),
            "i": React.createRef(),
            "4": React.createRef(),
            "o": React.createRef(),
            "t": React.createRef()
        };
        this.animationPool = {
            "p": ["up", "down", "left", "idle"],
            "i": ["up", "down", "idle"],
            "4": ["up", "down", "idle"],
            "o": ["up", "down", "idle"],
            "t": ["up", "down", "right", "idle"]
        }
        this.schedulerId = null;
    }

    componentDidMount() {
        console.log("Mounting Start");
        this.schedulerId = setInterval(() => {
            let k = choice(Object.keys(this.logoRefs))
            let animCss = choice(this.animationPool[k]);
            let stoneRef = this.logoRefs[k].current;
            animateElem(stoneRef, animCss).then((res) => {
                res.elem.classList.remove(res.css);
            });
        }, 2000);
    }

    componentWillUnmount() {
        console.log("Unmounting Start");
        if (this.schedulerId) {
            clearInterval(this.schedulerId);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="Start">
                    <div className="pivot-logo">
                        <div ref={this.logoRefs["p"]}>
                            <Stone size={2} code={2} text="P"/>
                        </div>
                        <div ref={this.logoRefs["i"]}>
                            <Stone size={2} code={2} text="I"/>
                        </div>
                        <div ref={this.logoRefs["4"]} className="tilt">
                            <Stone size={2} code={1} text="4"/>
                        </div>
                        <div ref={this.logoRefs["o"]}>
                            <Stone size={2} code={2} text="O"/>
                        </div>
                        <div ref={this.logoRefs["t"]}>
                            <Stone size={2} code={2} text="T"/>
                        </div>
                    </div>
                    <div className="welcome">
                        Connect 4 with a <span className="twist">🧠 twist.</span>
                    </div>
                    <div className="choice">
                        <a href="/bot">
                            <button type="button" className="btn-choice btn btn-bot btn-lg btn-block">
                                🤖 Beat the bot
                            </button>
                        </a>
                    </div>
                        {this.props.online ?
                            (
                                <div className="choice">
                                    <a href="/match">
                                        <button type="button" className="btn-choice btn btn-challenge btn-lg btn-block">
                                            🤼 Challenge a human
                                        </button>
                                    </a>
                                </div>
                            ) : (
                                <div className="choice">
                                    <button type="button" className="btn-choice btn btn-challenge btn-lg btn-block disabled">
                                        🤼 Challenge a human
                                    </button>
                                    <div className="info-offline">
                                        You need to be online for multi-player mode.
                                    </div>
                                </div>
                            )
                        }
                </div>
                <div className="start-footer">
                    <a href="https://twitter.com/pi4ot" target="_blank" rel="noreferrer">@pi4ot</a>
                </div>
            </React.Fragment>
        );
    }

}

export default Start;
