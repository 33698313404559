export class BotConfig {

    constructor(id, treeSearchDepth, name, shortName) {
        this.id = id;
        this.treeSearchDepth = treeSearchDepth;
        this.name = name;
        this.shortName = shortName;
    }

}

export const BOT_CONFIGS = [
    new BotConfig(1, 2, "👾 moderate", "👾"),
    new BotConfig(2, 3, "🤖 hard", "🤖"),
    new BotConfig(3, 4, "👹 evil", "👹")
];


export const storeBotId = (robot_id) => {
    localStorage.setItem('pi4ot-robot', robot_id);
}

export const getStoredBotId = () => {
    let bot_id = 2
    const stored_id = localStorage.getItem('pi4ot-robot');
    if (stored_id) {
        bot_id = parseInt(stored_id);
    }
    return bot_id;
}

export const storeMercyLevel = (mercy_level) => {
    localStorage.setItem('pi4ot-mercy', mercy_level);
}

export const getMercyLevel = () => {
    let mercy_level = 0
    const stored_mercy = localStorage.getItem('pi4ot-mercy');
    if (stored_mercy) {
        mercy_level = parseInt(stored_mercy);
    }
    return mercy_level;

}

export const getBotConfig = (bot_id) => {
    let config = undefined;
    for (let i = 0; i < BOT_CONFIGS.length; i++) {
        if (BOT_CONFIGS[i].id === bot_id) {
            config = BOT_CONFIGS[i];
            break;
        }
    }
    return config;
}


