import React, {Component} from "react";
import {
    BOARD_DO_NOTHING,
    BOARD_ROTATE_LEFT,
    BOARD_ROTATE_RIGHT,
    BOARD_UPSIDE_DOWN
} from "../utils/actions";
import {FaCaretUp, FaCaretDown, FaCaretLeft, FaCaretRight} from "react-icons/fa";
import './boardcontrols.component.css';


export class BoardControls extends Component {

    render() {
        return (
            <div className="container-controls">
                <div className={this.props.active ? 'controls-container-main active' : 'controls-container-main inactive'}>
                    <div>
                        <button className="btn btn-light btn-rotate" onClick={() => this.props.onRotateBoard(BOARD_ROTATE_LEFT)}><FaCaretLeft/></button>
                    </div>
                    <div className="controls-container-sub">
                        <div>
                            <button className="btn btn-light btn-rotate" onClick={() => this.props.onRotateBoard(BOARD_UPSIDE_DOWN)}><FaCaretUp/></button>
                        </div>
                        <div>
                            <button className="btn btn-light btn-rotate" onClick={() => this.props.onRotateBoard(BOARD_DO_NOTHING)}><FaCaretDown/></button>
                        </div>
                    </div>
                    <div>
                        <button className="btn btn-light btn-rotate" onClick={() => this.props.onRotateBoard(BOARD_ROTATE_RIGHT)}><FaCaretRight/></button>
                    </div>
                </div>
            </div>
        );
    }

}

export default BoardControls;