export const BOARD_ROTATE_LEFT = "L";
export const BOARD_ROTATE_RIGHT = "R";
export const BOARD_DO_NOTHING = "B"; // N
export const BOARD_UPSIDE_DOWN = "T"; // U

export const BOARD_ACTIONS = [
    BOARD_ROTATE_LEFT,
    BOARD_ROTATE_RIGHT,
    BOARD_DO_NOTHING,
    BOARD_UPSIDE_DOWN
];
