import React, {Component} from "react";
import BoardControls from "./boardcontrols.component";
import Stone from "./stone.component";
import {
    canPlaceStoneInColumn,
    createNewBoard,
    getClassnameForAction,
    hash_code,
    placeStoneInColumn,
    rotateBoard
} from "../utils/boardutils";
import {animateElem, scanBoardBTT, scanBoardLTR, scanBoardRTL, scanBoardTTB} from "../utils/animationutils";
import {BOARD_ROTATE_LEFT, BOARD_ROTATE_RIGHT, BOARD_UPSIDE_DOWN} from "../utils/actions";
import {STATE_MY_TURN} from "../utils/states";
import './board.component.css';
import './anim.css';
import {PLAYER_ONE} from "../utils/constants";


const BOARD_STATE_TRANSITION = "BOARD_STATE_TRANSITION";  // in transition, no interactions allowed
const BOARD_STATE_INSERT = "BOARD_STATE_INSERT";  // next action is to insert a stone
const BOARD_STATE_ROTATE = "BOARD_STATE_ROTATE";  // next action is to rotate the board


export class Move {

    constructor(player, col_idx, action=undefined, board=undefined) {
        this.player = player;
        this.col_idx = col_idx;
        this.action = action;
        this.board = board;
    }

    update(action, board) {
        this.action = action;
        this.board = board;
    }

    toString() {
        return this.player + ",("+this.col_idx+","+this.action+")," + hash_code(this.board);
    }

}


export class Board extends Component {
    highlight_column = true;
    column_selected = false;

    constructor(props) {
        super(props);
        this.state = {
            board: createNewBoard(this.props.dim),
            board_state: BOARD_STATE_INSERT,
            column_selected: -1,
            rotate_action: undefined,
            rotate_player_id: undefined,
            current_player: PLAYER_ONE
        }

        // elem refs for animations
        this.boardRef = React.createRef();
        this.headerRef = []
        this.gridRef = {}
        for (let i = 0; i < this.props.dim; i++) {
            this.headerRef[i] = React.createRef();
            for (let j = 0; j < this.props.dim; j++) {
                const id = this._genCellId(i, j);
                this.gridRef[id] = React.createRef();
            }
        }
    }

    _genCellId = (rowIdx, colIdx) => {
        return "cell" + rowIdx + "_" + colIdx;
    }

    getBoard = () => {
        return this.state.board;
    }

    reset = (current_player) => {
        this.setState({
            board: createNewBoard(this.props.dim),
            board_state: BOARD_STATE_INSERT,
            column_selected: -1,
            current_player: current_player
        });
    }

    setBoard = (board, current_player) => {
        this.setState({
            board: board,
            board_state: BOARD_STATE_INSERT,
            column_selected: -1,
            current_player: current_player
        });
    }

    selectColumnAndRotate = (column_idx, action, player_id) => {
        this._selectColumn(column_idx, player_id).then(() => {
            this._rotateBoard(action, player_id).then(() => {
                this.setState({
                    board_state: BOARD_STATE_INSERT
                }, () => {
                    this.highlight_column = true;
                });
            });
        });
    }

    _onSelectColumn = (column_idx, player_id) => {
        // prevent multiple clicks
        if (this.column_selected) {
            return;
        }
        this.column_selected = true;

        this._clearHoverCss();

        // prevent interfering interactions
        const my_turn = this.props.gameState === STATE_MY_TURN;
        if (my_turn && this.state.board_state === BOARD_STATE_INSERT) {
            this._selectColumn(column_idx, player_id);
        }
    }

    _clearHoverCss() {
        for (let i = 0; i < this.state.board.length; i++) {
            this.headerRef[i].current.classList.remove("show");
            for (let j = 0; j < this.state.board.length; j++) {
                this.gridRef[this._genCellId(i, j)].current.classList.remove("hover");
            }
        }
    }

    _onRotateBoard = (action, player_id) => {
        // prevent interfering interactions
        const my_turn = this.props.gameState === STATE_MY_TURN;
        if (my_turn && this.state.board_state === BOARD_STATE_ROTATE) {
            this._rotateBoard(action, player_id);
        }
    }

    _selectColumn = (column_idx, player_id) => {
        if (canPlaceStoneInColumn(this.state.board, column_idx)) {
            this.highlight_column = false;
            return new Promise((resolve) => {
                const elem = this.headerRef[column_idx].current;
                const target = scanBoardTTB(this.state.board, column_idx);
                const animName = "htb" + target.join("_");
                elem.classList.add("show");
                animateElem(elem, animName).then((res) => {
                    const new_board = placeStoneInColumn(this.state.board, column_idx, player_id);
                    res.elem.classList.remove(res.css);
                    this._clearHoverCss();
                    this.setState({
                        board: new_board,
                        column_selected: column_idx,
                        board_state: BOARD_STATE_ROTATE
                    }, () => {
                        resolve();
                    })
                });
            });
        } else {
            return Promise.resolve();
        }
    }

    /**
     * Rotate board and handle animations.
     *
     * @param action the rotation action
     * @param player_id the player id rotating the board
     */
    _rotateBoard = (action, player_id) => {
        const finishRotation = () => {
            const new_board = rotateBoard(this.state.board, action);
            let colIdx = this.state.column_selected;
            this.setState({
                board: new_board,
                current_player: this.state.current_player % 2 + 1,
                board_state: BOARD_STATE_INSERT,
                column_selected: -1,
                rotate_action: undefined
            }, () => {
                setTimeout(() => {
                    const new_move = new Move(player_id, colIdx, action, new_board);
                    this._onBoardChanged(new_move);
                }, 500);
            });
        }

        const translateDivs = () => {
            let animElems = null;
            let prefix = null;
            if (BOARD_ROTATE_RIGHT === action) {
                animElems = scanBoardRTL(this.state.board);
                prefix = "ltr";
            } else if (BOARD_ROTATE_LEFT === action) {
                animElems = scanBoardLTR(this.state.board);
                prefix = "rtl";
            } else if (BOARD_UPSIDE_DOWN === action) {
                animElems = scanBoardBTT(this.state.board);
                prefix = "btt";
            }
            if (animElems) {
                let allAnimations = [];
                for (let e in animElems) {
                    const rowIdxColIdx = e.split(",");
                    const elem = this.gridRef[this._genCellId(rowIdxColIdx[0], rowIdxColIdx[1])].current
                    const rowCol = animElems[e];
                    const animName = prefix + rowCol.join("_");
                    allAnimations.push(animateElem(elem, animName));
                }
                return Promise.all(allAnimations);
            } else {
                return Promise.resolve([]);
            }
        }

        return new Promise(resolve => {
            this.setState({
                board_state: BOARD_STATE_TRANSITION
            }, () => {
                let rotation_css_class = getClassnameForAction(action);
                // initiates rotation animation
                return animateElem(this.boardRef.current, rotation_css_class)
                    .then((res) => {
                        translateDivs().then((cells) => {
                            setTimeout(finishRotation, 0);
                            // clean up rotation and translation css
                            setTimeout(() => {
                                res.elem.classList.remove(res.css);
                                // clean up translate css
                                cells.forEach((c) => {
                                    c.elem.classList.remove(c.css);
                                });
                                this.column_selected = false;
                            }, 0);
                            resolve();
                        });
                    });
            });
        });

    }

    _onBoardChanged = (move) => {
        this.props.onBoardChanged(move);
    }

    _getCellPositionCSS = (row, col) => {
        return {
            "left": (col * 42) + "px",
            "top": (row * 42) + "px"
        };
    }

    _highlightColumn = (colIdx) => {
        const my_turn = this.props.gameState === STATE_MY_TURN;
        if (this.highlight_column && this.state.board_state === BOARD_STATE_INSERT && my_turn) {
            for (let i = 0; i < this.state.board.length; i++) {
                this.headerRef[i].current.classList.remove("show");
                for (let j = 0; j < this.state.board.length; j++) {
                    this.gridRef[this._genCellId(i, j)].current.classList.remove("hover");
                }
                this.gridRef[this._genCellId(i, colIdx)].current.classList.add("hover");
            }
            this.headerRef[colIdx].current.classList.add("show");
        }
    }

    render() {
        const my_turn = this.props.gameState === STATE_MY_TURN;
        const nextRotate = this.state.board_state === BOARD_STATE_ROTATE;

        return (
            <React.Fragment>
                <div className="board-container">
                    <div className="board-header">
                        {this.state.board.map((e, colIdx) => (
                            <div key={colIdx}
                                 ref={this.headerRef[colIdx]}
                                 className="board-header-cell bounce clickable"
                                 style={this._getCellPositionCSS(0, colIdx)}
                                 onClick={() => this._onSelectColumn(colIdx, this.state.current_player)}>
                                <Stone code={this.state.current_player}/>
                            </div>
                        ))}
                    </div>
                    <div ref={this.boardRef} className="board">
                        {this.state.board.map((row, rowIdx) => {
                            return row.map((col, colIdx) => {
                                return (
                                    <div key={this._genCellId(rowIdx, colIdx)}
                                         ref={this.gridRef[this._genCellId(rowIdx, colIdx)]}
                                         className="board-cell"
                                         style={this._getCellPositionCSS(rowIdx, colIdx)}
                                         onMouseEnter={() => this._highlightColumn(colIdx)}
                                         onTouchStart={() => this._highlightColumn(colIdx)}
                                         onClick={() => this._onSelectColumn(colIdx, this.state.current_player)}>
                                        <Stone code={this.state.board[rowIdx][colIdx]}/>
                                    </div>);
                            })
                        })}
                    </div>
                </div>
                <BoardControls active={my_turn && nextRotate} onRotateBoard={(action) => this._onRotateBoard(action, this.state.current_player)}/>
            </React.Fragment>
        );
    }
}

export default Board;