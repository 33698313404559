import React, {Component} from "react";
import './logo.component.css';


class Logo extends Component {

    render() {
        return (
            <div className="logo-container">
                <div className="logo-stone">
                    <div className="logo-stone-text">P</div>
                </div>
                <div className="logo-stone">
                    <div className="logo-stone-text">I</div>
                </div>
                <div className="logo-stone tilt filled">
                    <div className="logo-stone-text">4</div>
                </div>
                <div className="logo-stone">
                    <div className="logo-stone-text">O</div>
                </div>
                <div className="logo-stone">
                    <div className="logo-stone-text">T</div>
                </div>
            </div>
        );
    }

}

export default Logo;