import React, {Component} from "react";
import './stone.component.css';
import {PLAYER_ONE, PLAYER_TWO} from "../utils/constants";


export class Stone extends Component {

    codeToClassName = (code, size=1) => {
        let sizeClass = "stone";
        if (code === PLAYER_ONE) {
            sizeClass += " red"
        } else if (code === PLAYER_TWO) {
            sizeClass += " blue"
        }
        if (size === 2) {
            sizeClass += " big"
        }
        return sizeClass;
    }

    render() {
        return (
            <div className={this.codeToClassName(this.props.code, this.props.size)}>
                <div className="stone-text">{this.props.text}</div>
            </div>
        );
    }
}

export default Stone;