import React, {Component} from "react";
import Board from "./components/board.component";
import GameInfo from "./components/gameinfo.component";
import { getSessionIDFromURL, setSessionIDInURL } from "./utils/urlutils";
import {
    getSessionData,
    listenToSessionChange,
    newSession,
    registerPlayer2WithSession, resetSession, updateHistory,
    updateMove
} from "./utils/firebase";
import { determineBoardWinner } from "./utils/boardutils";
import {getMyID} from "./utils/idutils";
import {PLAYER_ONE, PLAYER_TWO} from "./utils/constants";
import {STATE_INVITE_PENDING, STATE_MY_TURN, STATE_OTHER_PLAYERS_TURN, STATE_WINS} from "./utils/states";
import './MatchGame.css';
import {InfonavComponent} from "./components/infonav.component";


export class MatchGame extends Component {

    constructor(props) {
        super(props);
        this.me_id =  getMyID();

        this.state = {
            me_player: PLAYER_ONE,
            current_player: PLAYER_ONE,
            game_state: STATE_INVITE_PENDING,
            sessionID: '',
            history: []
        }

        this.board = React.createRef();
    }

    componentDidMount() {
        let sessionID = getSessionIDFromURL();
        if (sessionID) {
            // register with session (if not already registered)
            getSessionData(sessionID).then((sessionData) => {
                if (sessionData) {
                    let p1_is_me = sessionData.p1 === this.me_id;
                    let p2_is_me = sessionData.p2 === this.me_id;
                    let p2_is_void = !sessionData.p2;
                    if (p2_is_void) {
                        // new session
                        if (p1_is_me) {
                            // re-register
                            listenToSessionChange(sessionID, this.sessionChanged.bind(this));
                            this.setState({
                                sessionID: sessionID,
                                me_player: PLAYER_ONE,
                                current_player: PLAYER_ONE,
                                game_state: STATE_INVITE_PENDING
                            });
                        } else {
                            // register as player 2
                            listenToSessionChange(sessionID, this.sessionChanged.bind(this));
                            registerPlayer2WithSession(sessionID, this.me_id).then(() => {
                                console.log('registered as player 2');
                            });
                            this.setState({
                                sessionID: sessionID,
                                me_player: PLAYER_TWO,
                                current_player: PLAYER_ONE,
                                game_state: STATE_OTHER_PLAYERS_TURN
                            });
                        }
                    } else if (p1_is_me || p2_is_me) {
                        // existing session
                        listenToSessionChange(sessionID, this.sessionChanged.bind(this));
                        let game_state = sessionData.next === this.me_id ? STATE_MY_TURN : STATE_OTHER_PLAYERS_TURN;
                        this.setState({
                            sessionID: sessionID,
                            me_player: p1_is_me ? PLAYER_ONE : PLAYER_TWO,
                            current_player: sessionData.next,
                            game_state: game_state
                        });
                        this.board.current.setMove(sessionData.move);
                    }
                }
            })
        } else {
            // create new session
            newSession(
                this.board.current.getBoard(),
                null,
                this.me_id,
                null,
                PLAYER_ONE
            ).then((sessionID) => {
                setSessionIDInURL(sessionID);
                this.setState({
                    sessionID: sessionID,
                    me_player: PLAYER_ONE,
                    current_player: PLAYER_ONE,
                    game_state: STATE_INVITE_PENDING
                })
                // new session created, listen to session changes
                listenToSessionChange(sessionID, this.sessionChanged.bind(this));
            })
        }
    }

    sessionChanged(sessionData) {
        console.log('session changed', sessionData);
        if (sessionData.move === "reset") {
            let next_action = sessionData.next === this.state.me_player ? STATE_MY_TURN : STATE_OTHER_PLAYERS_TURN;
            this.setState({
                current_player: sessionData.next,
                game_state: next_action
            });
            this.board.current.reset(sessionData.next);
        } else if (sessionData.p1 && sessionData.p2) {
            // update other players move
            if (sessionData.next === this.state.me_player) {
                const win_state = determineBoardWinner(sessionData.board);
                const next_turn = sessionData.next === this.state.me_player ? STATE_MY_TURN : STATE_OTHER_PLAYERS_TURN;
                const new_state = win_state == null ? next_turn : win_state;
                this.setState({
                    current_player: sessionData.next,
                    game_state: new_state
                });
                // replay
                if (sessionData.move) {
                    const mover_id = sessionData.next % 2 + 1;
                    const col_idx = parseInt(sessionData.move[0]);
                    const action = sessionData.move[1];
                    this.board.current.selectColumnAndRotate(col_idx, action, mover_id);
                }
            }
        }
    }

    onBoardChanged = (move) => {
        console.log("board changed");
        if (move.player === this.state.me_player) {
            let other_player = this.state.me_player % 2 + 1;
            const win_state = determineBoardWinner(move.board);
            const new_state = win_state == null ? STATE_OTHER_PLAYERS_TURN : win_state;
            this.setState({
                current_player: other_player,
                game_state: new_state,
                history: [...this.state.history, move]
            });
            this.updateSession(
                move.col_idx + move.action,
                move.board,
                other_player
            );
            // update session history
            let history = this.state.history;
            if (this.state.sessionID && history.length > 0) {
                updateHistory(this.state.sessionID, history[history.length - 1]).then(r => {
                    console.log('updated history', r);
                })
            }
        } else {
            let other_player = this.state.me_player % 2 + 1;
            this.setState({
                current_player: other_player,
                game_state: STATE_MY_TURN
            });
        }
    }

    updateSession(my_move, board, next) {
        updateMove(this.state.sessionID, my_move, board, next).then(() => {
            if (!STATE_WINS.includes(this.state.game_state)) {
                this.setState({
                    next_action: STATE_OTHER_PLAYERS_TURN
                });
            }
        });
    }

    restartSession = () => {
        return resetSession(this.state.sessionID, this.state.me_player);
    }

    render() {
        return (
            <React.Fragment>
                <InfonavComponent showMenu={false}/>
                <div className="Game">
                    <GameInfo gameState={this.state.game_state} mePlayer={this.state.me_player} onRestart={this.restartSession}/>
                    <Board ref={this.board} dim={this.props.dim} gameState={this.state.game_state} onBoardChanged={this.onBoardChanged}/>
                </div>
            </React.Fragment>
        )
    }

}

export default MatchGame;
