
export const getSessionIDFromURL = () => {
    const hash = window.location.hash;
    const sessionID = hash.replace('#','');
    return sessionID ? sessionID : null;
}

export const setSessionIDInURL = (sessionID) => {
    window.history.replaceState(sessionID, 'Session '+sessionID, '/match/#'+sessionID);
}
