export const STATE_WIN_PLAYER_ONE = "STATE_WIN_PLAYER_ONE";
export const STATE_WIN_PLAYER_TWO = "STATE_WIN_PLAYER_TWO";
export const STATE_WIN_DEUCE = "STATE_WIN_DEUCE";
export const STATE_INVITE_PENDING = "STATE_INVITE_PENDING";
export const STATE_OTHER_PLAYERS_TURN = "STATE_OTHER_PLAYERS_TURN";
export const STATE_MY_TURN = "STATE_MY_TURN";

export const STATE_WINS = [
    STATE_WIN_PLAYER_ONE,
    STATE_WIN_PLAYER_TWO,
    STATE_WIN_DEUCE
];