// public API

import {
    canPlaceStoneInColumn,
    createNewBoard,
    getDiagonals,
    hash_code,
    placeStoneInColumn,
    rotateBoard
} from "./boardutils";
import {PLAYER_CODES, PLAYER_ONE, PLAYER_TWO} from "./constants";
import {BOARD_ACTIONS} from "./actions";

/**
 * Let the bot play a move
 * @param board
 * @param player_code
 * @param strength search depth
 * @param mercy_level 0-100 percentage of mercy
 * @returns [idx,action] array
 */
export const getBotMove = (board, player_code, strength, mercy_level) => {
    const ab = new AlphaBeta(player_code, 4, strength);
    const b = new Board(board.length, 4);
    b.board = board;
    return ab.findBestMove(b, mercy_level/100);
}


export const MAX_POINTS = 10000
export const WIN_TH_POINTS = 5000

const INFINITY = Number.POSITIVE_INFINITY
const NEG_INFINITY = Number.NEGATIVE_INFINITY


// utils

export function choice(array) {
    const idx = Math.floor((Math.random()*array.length));
    return array[idx];
}

function is_contained(pattern, text) {
    return text.includes(pattern) ? 1 : 0;
}

function getColumn(board, col) {
    let column = [];
    for(let i = 0; i < board.length; i++){
        column.push(board[i][col]);
    }
    return column;
}


export class Move {

    constructor(move, result_board) {
        this.move = move;
        this.result_board = result_board;
        this.parity_moves = [];
    }

    add_parity_move(move) {
        if (! this.parity_moves.includes(move)) {
            this.parity_moves.push(move)
        }
    }

    get_all_parity_moves() {
        let all_moves = [this.move];
        all_moves.concat(this.parity_moves);
        return all_moves
    }
}

export class Board {

    create_board() {
        return createNewBoard(this.dim);
    }

    constructor(dim=7, seq_len=4) {
        this.dim = dim;
        this.seq_len = seq_len;
        // row/col indices, top/left = 0/0, bottom/right = dim-1/dim-1
        this.board = this.create_board();
        this.current_player = 1;
    }

    _put_player_in_col(player, col) {
        const canPlace = canPlaceStoneInColumn(this.board, col);
        if (canPlace) {
            this.board = placeStoneInColumn(this.board, col, player);
        } else {
            throw new Error("Column full at: "+col);
        }
    }

    _rotate(action) {
        this.board = rotateBoard(this.board, action);
    }

    play(player_code, move) {
        let col = undefined;
        let action = undefined;
        if (move.length === 2) {
            col = move[0];
            action = move[1];
        } else {
            throw new Error("Move must a (col, action) tuple, is " + move);
        }
        if (! PLAYER_CODES.includes(player_code)) {
            throw new Error("Invalid player " + player_code);
        }
        if (col < 0 || col >= this.dim) {
            throw new Error("Invalid col " + col);
        }
        if (! BOARD_ACTIONS.includes(action)) {
            throw new Error("Invalid action " + action);
        }

        this._put_player_in_col(player_code, col);
        this._rotate(action);

        this.current_player = player_code % 2 + 1;
        return this;
    }

    hash_code() {
        return hash_code(this.board);
    }

    is_equal(other) {
        return this.hash_code() === other.hash_code();
    }

    copy() {
        let new_board;
        new_board = new Board(this.dim, this.seq_len);
        new_board.board = this.board.map(col => { return col.slice(); });
        return new_board;
    }

    get_valid_moves() {
        const moves = [];
        for (let col_idx = 0; col_idx < this.dim; col_idx++) {
            if (this.board[0][col_idx] === 0) {
                BOARD_ACTIONS.forEach(a => {
                    moves.push([col_idx, a]);
                });
            }
        }
        return moves;
    }

    get_unique_moves(player) {
        const valid_moves = this.get_valid_moves();
        if (! valid_moves) {
            return [];
        }
        const board_hash2move = {};
        const unique_moves = [];

        valid_moves.forEach(move => {
            const resulting_board = this.copy().play(player, move)
            const board_hash = resulting_board.hash_code();
            if (! (board_hash in board_hash2move)) {
                const m = new Move(move, resulting_board);
                board_hash2move[board_hash] = m;
                unique_moves.push(m);
            } else {
                const k = board_hash2move[board_hash];
                k.add_parity_move(move);
            }
        })
        return unique_moves
    }

    is_won() {
        return EVALUATOR.is_won(this);
    }

}

export class Pattern {

    constructor(template, seq_len, check_diag, points) {
        if (typeof template !== 'string' || template.length === 0) {
            throw new Error("template is expected to be a string")
        }
        if (! template.includes("%")) {
            throw new Error("template is expected to have a % placeholder");
        }
        this.template = template;
        if (seq_len < 1) {
            throw new Error("seq_ln must be >= 1");
        }
        this.seq_len = seq_len;
        this.check_diag = check_diag;
        this.points = points;
    }

    get_pattern(player_code) {
        if (!PLAYER_CODES.includes(player_code)) {
            throw new Error("Unknown player code: "+player_code);
        }
        const seq = player_code.toString().repeat(this.seq_len);
        return this.template.replace(/%/g, seq);
    }
}


export class PatternGenerator {

    constructor(seq_len) {
        this.seq_len = seq_len;
    }

    generate() {
        let patterns = [];
        for (let i = 1; i <= this.seq_len; i++) {
            if (i > 1) {
                const points = i < this.seq_len ? Math.ceil(2 ** (i + 0.5)) : MAX_POINTS;
                const base = new Pattern("%", i, true, points);
                patterns.push(base);
            }
            if (i < this.seq_len) {
                const left_empty = new Pattern("0%", i, false, 2 ** (i + 1))
                const right_empty = new Pattern("%0", i, false, 2 ** (i + 1))
                patterns.concat([left_empty, right_empty])
            }
        }
        return patterns
    }
}


export class BoardEvaluator {

    constructor(seq_len) {
        this.seq_len = seq_len;
        this.patterns = new PatternGenerator(this.seq_len).generate();
    }

    evaluate(board, player_code) {
        const res = this._count_pattern_matches(board, this.patterns);
        if (player_code === PLAYER_ONE) {
            return res.total_1 - res.total_2;
        } else if (player_code === PLAYER_TWO) {
            return res.total_2 - res.total_1;
        } else {
            return 0;
        }
    }

    _count_pattern_matches(board_obj, patterns) {
        const board = board_obj.board;
        let total_1 = 0;
        let total_2 = 0;
        let dim = board.length;
        for (let c = 0; c < dim; c++) {
            const col = board[c].join('');
            const row = getColumn(board, c).join('');
            const diagonals = getDiagonals(board, this.seq_len);
            for (let p = 0; p < patterns.length; p++) {
                const pattern = patterns[p];
                let count_p1 = 0;
                let count_p2 = 0;
                const pattern_1 = pattern.get_pattern(PLAYER_ONE);
                const pattern_2 = pattern.get_pattern(PLAYER_TWO);
                count_p1 += is_contained(pattern_1, col);
                count_p2 += is_contained(pattern_2, col);
                count_p1 += is_contained(pattern_1, row);
                count_p2 += is_contained(pattern_2, row);
                if (pattern.check_diag) {
                    diagonals.forEach(diag_array => {
                        const diag = diag_array.join('');
                        count_p1 += is_contained(pattern_1, diag);
                        count_p2 += is_contained(pattern_2, diag);
                    })
                }
                total_1 += count_p1 * pattern.points;
                total_2 += count_p2 * pattern.points;
            }
        }
        return { total_1, total_2}
    }

    is_won(board) {
        const base = new Pattern("%", this.seq_len, true, MAX_POINTS);
        const res = this._count_pattern_matches(board, [base]);
        if (res.total_1 === 0 && res.total_2 === 0) {
            return -1;
        } else if (res.total_1 === res.total_2) {
            return 0;
        } else if (res.total_1 > res.total_2) {
            return PLAYER_ONE;
        } else if (res.total_2 > res.total_1) {
            return PLAYER_TWO;
        }
    }

}

const EVALUATOR = new BoardEvaluator(4);


class SearchResults {

    constructor() {
        this.moves_scores = [];
        this.best_move = null;
        this.winning_moves = [];
        this.losing_moves = [];
    }

    update_best_move(move) {
        this.best_move = move;
    }

    update(move, score) {
        this.moves_scores.push([move, score]);
    }

    update_winning(move) {
        this.winning_moves.push(move);
    }

    update_losing(move) {
        this.losing_moves.push(move);
    }

    get_best_move(delta=0.1) {
        if (this.winning_moves.length > 0) {
            return choice(this.winning_moves).move;
        } else if (this.best_move) {
            if (delta > 0) {
                // get score for best move
                const bm = this.moves_scores.filter(move_score => { return move_score[0] === this.best_move; });
                const best_score = bm[0][1];
                // console.log("best move", bm[0][0].move.join(""), "->", best_score);

                // get all moves with score within delta percent of best score
                const candidates = this.moves_scores.filter(move_score => {
                    return ((best_score - move_score[1]) / best_score + 0.0001) <= delta;  // score can be 0 ...
                });
                // console.log("candidates", candidates.map(c => c[0].move.join("")+"->"+c[1]));
                let the_choice = choice(candidates);
                // console.log("the choice", the_choice[0].move.join("")+"->"+the_choice[1]);
                return the_choice[0].move;
            } else {
                return choice(this.best_move.get_all_parity_moves());
            }
        } else if (this.losing_moves.length > 0) {
            return choice(this.losing_moves).move;
        }
    }

    log() {
        // console.log("### BOT_MOVE_ANALYSIS ###");
        // console.log("Winning moves:", this.winning_moves.map(m => m.move.join("")));
        // console.log("Losing moves:", this.losing_moves.map(m => m.move.join("")));
        // if (this.best_move) {
        //     console.log("Best move:", this.best_move.move.join(""));
        // }
        // const sorted = this.moves_scores.sort((a, b) => b[1] - a[1]);
        // sorted.forEach(move => {
        //     const m = move[0].move.join("")
        //     console.log(m, "->", move[1]);
        // });
    }

}

export class AlphaBeta {

    VERSION = "1.0"

    constructor(code, seq_len, max_depth) {
        if (! PLAYER_CODES.includes(code)) {
            throw new Error("Invalid player code "+code);
        }
        this.code = code;
        this.max_depth = max_depth
        this.evaluator = new BoardEvaluator(seq_len)
    }

    get_code() {
        return this.code;
    }

    get_opponent_code() {
        return this.code % 2 + 1;
    }

    identify() {
        return "alpha-beta-v"+this.VERSION+"(max_depth:"+this.max_depth+")"
    }

    findBestMove(board, delta=0.1) {
        const results = this.alpha_beta_search(board);
        results.log();
        return results.get_best_move(delta);
    }

    alpha_beta_search(board) {
        let alpha = NEG_INFINITY;  // max value the maximizer (myself) can guarantee at that level or below
        const beta = INFINITY;     // min value the minimizer (opponent) can guarantee at that level or below

        const search_results = new SearchResults();

        // iterate all moves and pick move with max alpha value
        const moves = board.get_unique_moves(this.get_code());
        for (let i = 0; i < moves.length; i++) {
            const move = moves[i];
            const win_state = move.result_board.is_won();
            if (win_state === this.get_code()) {
                search_results.update_winning(move);
                return search_results;  // no need to search further
            } else if (win_state === this.get_opponent_code()) {
                search_results.update_losing(move);
            } else {
                const move_value = this.min_value(move, alpha, beta, 1);  // next level is minimizer's choice
                search_results.update(move, move_value);
                if (move_value > alpha) {
                    alpha = move_value;
                    search_results.update_best_move(move);
                }
            }
        }
        return search_results;
    }

    max_value(move, alpha, beta, depth) {
        if (this.is_terminal(move, depth)) {
            return this.get_value(move);
        }
        let value = alpha;
        const moves = move.result_board.get_unique_moves(this.get_code());
        for (let i = 0; i < moves.length; i++) {
            const move = moves[i];
            let move_value = this.get_value(move);
            if (move_value > WIN_TH_POINTS) {
                return move_value;
            }
            move_value = this.min_value(move, alpha, beta, depth + 1);
            value = Math.max(value, move_value);
            if (value >= beta) {
                return value;
            }
            alpha = Math.max(alpha, value)
        }
        return value
    }

    min_value(move, alpha, beta, depth) {
        if (this.is_terminal(move, depth)) {
            return this.get_value(move);
        }
        let value = beta;
        const moves = move.result_board.get_unique_moves(this.get_opponent_code())
        for (let i = 0; i < moves.length; i++) {
            const move = moves[i];
            let move_value = this.get_value(move);
            if (move_value < -WIN_TH_POINTS) {
                return move_value;
            }
            move_value = this.max_value(move, alpha, beta, depth + 1);
            value = Math.min(value, move_value);
            if (value <= alpha) {
                return value;
            }
            beta = Math.min(beta, value);
        }
        return value
    }

    is_terminal(move, depth) {
        return depth >= this.max_depth || move.result_board.is_won() > -1;
    }

    get_value(move) {
        return this.evaluator.evaluate(move.result_board, this.get_code())
    }

}
