import {NO_PLAYER} from "./constants";


export const animateElem = (elem, animClass) => {
    return new Promise(resolve => {
        elem.addEventListener('animationend', () => {
            resolve({ "elem": elem, "css": animClass });
        }, { once: true });
        elem.classList.add(animClass);
    });
}

/**
 * Scan board for right (90 deg clockwise) rotation
 *
 * @param board
 * @returns dict of {from: to} column indices, from < to
 */
export const scanBoardRTL = (board) => {
    const dim = board.length;
    const res = {};
    for (let rowIdx = 0; rowIdx < dim; rowIdx++) {
        let toIdx = dim - 1;
        if (board[rowIdx][toIdx] === NO_PLAYER) {
            for (let colIdx = dim - 2; colIdx >= 0; colIdx--) {
                if (board[rowIdx][colIdx] !== NO_PLAYER) {
                    res[[rowIdx, colIdx]] = [colIdx, toIdx];
                    toIdx -= 1;
                }
            }
        }
    }
    return res;
}

/**
 * Scan board for left (90 deg counter-clockwise) rotation
 *
 * @param board
 * @returns dict of {from: to} column indices, from > to
 */
export const scanBoardLTR = (board) => {
    const dim = board.length;
    const res = {};
    for (let rowIdx = 0; rowIdx < dim; rowIdx++) {
        let toIdx = 0;
        if (board[rowIdx][toIdx] === NO_PLAYER) {
            for (let colIdx = 1; colIdx < dim; colIdx++) {
                if (board[rowIdx][colIdx] !== NO_PLAYER) {
                    res[[rowIdx, colIdx]] = [colIdx, toIdx];
                    toIdx += 1;
                }
            }
        }
    }
    return res;
}

/**
 * Scan board for bottom to top (180 deg) rotation
 *
 * @param board
 * @returns dict of {from: to} column indices, from > to
 */
export const scanBoardBTT = (board) => {
    const dim = board.length;
    const res = {};
    for (let colIdx = 0; colIdx < dim; colIdx++) {
        let toIdx = 0;
        if (board[toIdx][colIdx] === NO_PLAYER) {
            for (let rowIdx = 1; rowIdx < dim; rowIdx++) {
                if (board[rowIdx][colIdx] !== NO_PLAYER) {
                    res[[rowIdx, colIdx]] = [rowIdx, toIdx];
                    toIdx += 1;
                }
            }
        }
    }
    return res;
}

export const scanBoardTTB = (board, colIdx) => {
    const dim = board.length;
    let res = [0, dim];
    for (let rowIdx = 0; rowIdx < dim; rowIdx++) {
        if (board[rowIdx][colIdx] !== NO_PLAYER) {
            res = [0, rowIdx];
            break;
        }
    }
    return res;
}