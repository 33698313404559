
import React, {Component} from "react";
import './board.component.css';
import {Button, Modal} from "react-bootstrap";
import {BOT_CONFIGS} from "../utils/botutils";


export class BotConfigDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
        }
    }

    showDialog(show = true) {
        this.setState({
            show: show
        });
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={() => this.setState({ show: false })} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Bot Selector</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="bot-form-label">Bot Type</div>
                        <div className="form-group bot-form">
                            {BOT_CONFIGS.map((config) => (
                                <div key={config.id} className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                           id={"bot"+config.id} value={config.id} checked={this.props.selectedBot === config.id}
                                           onChange={e => this.props.onBotSelected(config.id)}/>
                                    <label className="form-check-label" htmlFor={"bot"+config.id}>{config.name}</label>
                                </div>
                            ))}
                        </div>
                    </form>
                    <form>
                        <div className="bot-form-label">Have Mercy!</div>
                        <div className="form-group bot-form">
                            {[0,10,20,30].map((m) => (
                                <div key={"m"+m} className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                           id={"mercy"+m} value={m} checked={this.props.mercyLevel === m}
                                           onChange={e => this.props.onMercySelected(m)}/>
                                    <label className="form-check-label" htmlFor={"mercy"+m}>{m}%</label>
                                </div>
                            ))}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.showDialog(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default BotConfigDialog;