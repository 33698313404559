import React, {Component} from "react";
import {
    STATE_INVITE_PENDING, STATE_MY_TURN,
    STATE_WIN_DEUCE, STATE_WIN_PLAYER_ONE, STATE_WIN_PLAYER_TWO, STATE_WINS
} from "../utils/states";
import Stone from "./stone.component";
import {BsArrowLeftRight, BsBoxArrowUpRight, BsArrowCounterclockwise} from "react-icons/bs";
import './gameinfo.component.css';
import {PLAYER_ONE, PLAYER_TWO} from "../utils/constants";


class WinInfo extends Component {

    render() {
        let i_won = ((this.props.gameState === STATE_WIN_PLAYER_ONE) && (this.props.playerCode === PLAYER_ONE))
                         || ((this.props.gameState === STATE_WIN_PLAYER_TWO) && (this.props.playerCode === PLAYER_TWO));
        let winner_code = i_won ? this.props.playerCode : (this.props.playerCode % 2 + 1);
        let deuce = this.props.gameState === STATE_WIN_DEUCE;

        return (
            <div className="gameinfo-wininfo">
                <div className="gameinfo-row">
                    {!deuce && <Stone code={winner_code}/>}
                    <div className="winner-text">
                        {deuce ? '⚖️ Deuce!' : (i_won ? 'You won! 🎉' : 'won! 👏')}
                    </div>
                </div>
            </div>
        );
    }

}

export class GameInfo extends Component {

    shareUrl = () => {
        if (navigator.share === undefined) {
            navigator.clipboard.writeText(window.location.toString()).then(() => {
                alert('Game Url copied to clipboard!\nShare with other player to start playing.');
            });
        } else {
            navigator.share({
                title: 'Pi4ot',
                text: 'Hey, join me for a pi4ot match!',
                url: window.location
            });
        }
    }

    render() {
        let gameinfo;
        const my_turn = this.props.gameState === STATE_MY_TURN;
        const my_code = this.props.mePlayer;
        const other_code = my_code % 2 + 1;

        if (this.props.gameState === STATE_INVITE_PENDING) {
            // initial state - invite other player
            gameinfo = (
                <div className="container-gameinfo">
                    <button className="btn btn-outline-secondary btn-invite" onClick={() => this.shareUrl()}>
                        Invite Player <BsBoxArrowUpRight className="player-invite"/>
                    </button>
                    <div className="gameinfo-comment">Share the game url with other player to join.</div>
                </div>
            )
        } else if (STATE_WINS.includes(this.props.gameState)) {
            // terminal state - win or deuce
            gameinfo = (
                <div className="container-gameinfo">
                    <WinInfo playerCode={my_code} gameState={this.props.gameState}/>
                    <div className="re-match">
                        <button className="btn btn-outline-secondary btn-invite" onClick={() => this.props.onRestart()}>
                            Re-match
                        </button>
                        {this.props.botSelection && (
                            <button className="btn btn-outline-secondary btn-undo" onClick={() => this.props.onRestart()}>
                                <BsArrowCounterclockwise className="name-edit" onClick={(e) => {this.props.onUndo(); e.stopPropagation()}}/>
                            </button>
                        )}
                    </div>
                    <div className="gameinfo-comment">Hit Re-match to restart the game.</div>
                </div>
            )
        } else {
            // game state - players take turns
            let comment = "Waiting for other player's move ...";
            if (my_turn) {
                comment = "Your turn. Select a column and pivot the board.";
            }

            gameinfo = (
                <div className="container-gameinfo">
                    <div className="gameinfo-row">
                        <div className={'gameinfo-player-border ' + (my_turn ? 'gameinfo-player-active' : '')}>
                            <div className={'gameinfo-player ' + (my_turn ? 'player'+my_code : 'player-inactive')}>
                                <Stone code={my_code}/>
                                <div className="player-name">You</div>
                            </div>
                        </div>
                        <div className="player-vs">
                            {/*<BsArrowLeftRight />*/}
                            vs
                        </div>
                        <div className={'gameinfo-player-border clickable ' + (!my_turn ? 'gameinfo-player-active' : '')}>
                            <div className={'gameinfo-player ' + (!my_turn ? 'player'+other_code : 'player-inactive')}>
                                <Stone code={other_code}/>
                                <div className={'player-name ' + (this.props.botSelection && !my_turn ? 'rotation' : '')}>{this.props.otherPlayerName || 'Other'}</div>
                                { /*Bot only visual*/ }
                                {/*{this.props.state.bot_selection && this.props.state.history.length === 0 && <BsArrowCounterclockwise className="name-edit" onClick={() => this.props.onUndo()}/>}*/}
                                {this.props.botSelection && <BsArrowCounterclockwise className="name-edit" onClick={(e) => {this.props.onUndo(); e.stopPropagation()}}/>}
                            </div>
                        </div>
                    </div>
                    <div className="gameinfo-comment">{comment}</div>
                </div>
            )
        }
        return (
            <React.Fragment>
                {gameinfo}
            </React.Fragment>
        )
    }
}

export default GameInfo;