import './components/boardcontrols.component.css';
import './BotGame.css';
import Board from "./components/board.component";
import React, {Component} from "react";
import {
    STATE_MY_TURN, STATE_OTHER_PLAYERS_TURN
} from "./utils/states";
import {PLAYER_ONE} from "./utils/constants";
import {
    determineBoardWinner,
} from "./utils/boardutils";
import GameInfo from "./components/gameinfo.component";
import {getBotMove} from "./utils/bot";
import {getStoredBotId, storeBotId, getBotConfig, getMercyLevel, storeMercyLevel} from "./utils/botutils";
import {getMyID} from "./utils/idutils";
import {BotConfigDialog} from "./components/botconfig.component";
import {updateHistory} from "./utils/firebase";
import {InfonavComponent} from "./components/infonav.component";


export class BotGame extends Component {

    constructor(props) {
        super(props);

        // get bot config
        const bot_id = getStoredBotId();
        const mercy_level = getMercyLevel();
        const botConfig = getBotConfig(bot_id);
        const bot_name = botConfig.shortName;

        // refs
        this.botConfigDialog = React.createRef();
        this.board = React.createRef();

        this.me_id = getMyID();

        this.state = {
            me_player: PLAYER_ONE,
            current_player: PLAYER_ONE,
            game_state: STATE_MY_TURN,
            other_player_name: bot_name,
            bot_selection: bot_id,
            mercy_level: mercy_level,
            show_bot_config: false,
            history: [] // stores moves
        }
    }

    showBotConfig(show = true) {
        this.botConfigDialog.current.showDialog(show);
    }

    setBot(bot_id) {
        let botName = getBotConfig(bot_id).shortName;
        this.setState({
            bot_selection: bot_id,
            other_player_name: botName
        }, () => {
            storeBotId(bot_id);
        });
    }

    setMercyLevel(level) {
        this.setState({
            mercy_level: level
        }, () => {
            storeMercyLevel(level);
        });
    }

    restartSession = () => {
        // hard reset
        window.location.href = "/bot";
    }

    computerToMakeTurn() {
        const searchDepth = getBotConfig(this.state.bot_selection).treeSearchDepth;
        const the_board = this.board.current;
        const move = getBotMove(the_board.getBoard(), this.state.current_player, searchDepth, this.state.mercy_level);
        const action = move[1];
        const columnIdx = parseInt(move[0]);
        the_board.selectColumnAndRotate(columnIdx, action, this.state.current_player);
    }

    onBoardChanged = (move) => {
        // console.log("onBoardChanged");
        const win_state = determineBoardWinner(move.board);
        let game_state = this.state.me_player === move.player ? STATE_OTHER_PLAYERS_TURN : STATE_MY_TURN;
        if (win_state != null) {
            game_state = win_state;
            // TODO log history on server for analysis / learning
        }
        this.setState({
            game_state: game_state,
            current_player: move.player % 2 + 1,
            history: [...this.state.history, move]
        }, () => {
            if (this.state.game_state === STATE_OTHER_PLAYERS_TURN) {
                this.computerToMakeTurn();
            }
        });
    }

    // TODO relevant for match game
    updateSession = () => {
        let history = this.state.history;
        if (this.state.sessionID) {
            return updateHistory(this.state.sessionID, history[history.length-1])
        }
    }

    undo = () => {
        if (this.state.history.length > 3) {
            const new_history = this.state.history.slice(0,-2); // remove last two moves
            const my_prev_move = new_history[new_history.length - 1];
            this.setState({
                history: new_history,
                game_state: STATE_MY_TURN
            }, () => {
                // note: old_move.player is the player who made the move resulting in the old_move.board state
                this.board.current.setBoard(my_prev_move.board, my_prev_move.player % 2 + 1);
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <InfonavComponent showMenu={true} onMenuClicked={() => this.showBotConfig(true)}/>
                <div className="Game">
                    <GameInfo
                        gameState={this.state.game_state}
                        mePlayer={this.state.me_player}
                        botSelection={this.state.bot_selection}
                        otherPlayerName={this.state.other_player_name}
                        onUndo={this.undo} onRestart={this.restartSession}/>
                    <Board dim={this.props.dim} ref={this.board} gameState={this.state.game_state} onBoardChanged={this.onBoardChanged}/>
                </div>
                <BotConfigDialog
                    ref={this.botConfigDialog}
                    onBotSelected={(bot_id) => this.setBot(bot_id)}
                    onMercySelected={(m) => this.setMercyLevel(m)}
                    selectedBot={this.state.bot_selection}
                    mercyLevel={this.state.mercy_level}/>
            </React.Fragment>
        )
    }

}

export default BotGame;
