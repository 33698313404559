import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React, {Component} from "react";
import MatchGame from "./MatchGame";
import BotGame from "./BotGame";
import Start from "./Start";


export class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            online: false
        }
    }

    componentDidMount() {
        this.setState({
            online: navigator.onLine
        });
        window.addEventListener('offline', (e) => {
            this.setState({online: false});
        });
        window.addEventListener('online', (e) => {
            this.setState({online: false});
        });
    }

    render() {
        return (
            <BrowserRouter>
                <div className="App">
                    <Routes>
                        <Route exact path="/" element={<Start online={this.state.online}/>}/>
                        <Route exact path="/bot" element={<BotGame dim={7}/>}/>
                        <Route exact path="/match" element={<MatchGame dim={7} />}/>
                    </Routes>
                </div>
            </BrowserRouter>
        );
    }

}
